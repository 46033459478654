var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('section',{staticClass:"family-list-main"},[_c('RequestStatusFeedbackComponent',{staticClass:"mt-4",attrs:{"solid":""}}),_c('v-img',{staticClass:"mx-auto mb-8",staticStyle:{"width":"450px","margin-top":"80px"},attrs:{"contain":"","src":require("@/assets/img/portada-catalogo.png"),"max-width":"100%"}}),_c('SpinnerComponent',{attrs:{"spinning":_vm.families.isLoading,"text":"Cargando familias..."}}),_c('EmptyComponent',{staticClass:"dasdasdasda",attrs:{"empty":!_vm.families.isLoading && !_vm.families.data.length,"text":"No se encontraron familias."}},[_c('v-row',{staticClass:"flex-wrap",staticStyle:{"gap":"2%"},attrs:{"justify":"center"}},_vm._l((_vm.families.data),function(family){return _c('v-card',{key:family.id,attrs:{"elevation":"10","rounded":"xxl","width":"220px","height":"230px","color":"#f2f2f2"},on:{"click":function($event){return _vm.$router.push({
              name: 'familia-de-productos',
              params: { familyId: family.id },
            })}}},[_c('v-card-title',{staticClass:"pa-0",staticStyle:{"height":"140px"},style:({
              backgroundImage: 'url(' + family.icono + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            })}),_c('v-card-text',{staticClass:"mt-1"},[_c('v-row',{staticClass:"align-center flex-column mt-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-btn',{staticClass:"blue-gradient-btn white--text adjustable-button pa-0 ma-0 rounded-lg elevation-3",style:(_vm.calculateButtonStyle(family.nombre))},[_c('span',{staticClass:"pa-1"},[_vm._v(_vm._s(family.nombre))])]),_c('span',{staticClass:"pa-0 ma-0",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(family.codigo_convenio ?? "Sin código convenio"))]),_c('span',{staticClass:"pa-0 ma-0",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(family.fecha_convenio ? _vm.moment(family.fecha_convenio).format("DD/MM/YYYY") : "Sin fecha convenio"))])],1)],1)],1)}),1)],1)],1),_c('div',{staticClass:"family-list-footer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }