<template>
  <v-container>
    <section class="family-list-main">
      <RequestStatusFeedbackComponent class="mt-4" solid />

      <v-img
        contain
        src="@/assets/img/portada-catalogo.png"
        style="width: 450px; margin-top: 80px"
        class="mx-auto mb-8"
        max-width="100%"
      />

      <SpinnerComponent
        :spinning="families.isLoading"
        text="Cargando familias..."
      />
      <EmptyComponent
        :empty="!families.isLoading && !families.data.length"
        text="No se encontraron familias."
        class="dasdasdasda"
      >
        <v-row justify="center" class="flex-wrap" style="gap: 2%">
          <v-card
            v-for="family in families.data"
            :key="family.id"
            elevation="10"
            rounded="xxl"
            width="220px"
            height="230px"
            color="#f2f2f2"
            @click="
              $router.push({
                name: 'familia-de-productos',
                params: { familyId: family.id },
              })
            "
          >
            <v-card-title
              :style="{
                backgroundImage: 'url(' + family.icono + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }"
              style="height: 140px"
              class="pa-0"
            >
            </v-card-title>
            <v-card-text class="mt-1">
              <v-row justify="center" no-gutters class="align-center flex-column mt-2">
                <v-btn
                  class="blue-gradient-btn white--text adjustable-button pa-0 ma-0 rounded-lg elevation-3"
                  :style="calculateButtonStyle(family.nombre)"
                  ><span class="pa-1">{{ family.nombre }}</span></v-btn
                >
                <span class="pa-0 ma-0" style="font-size: 10px">{{
                  family.codigo_convenio ?? "Sin código convenio"
                }}</span>
                <span class="pa-0 ma-0" style="font-size: 10px">{{
                  family.fecha_convenio
                    ? moment(family.fecha_convenio).format("DD/MM/YYYY")
                    : "Sin fecha convenio"
                }}</span>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </EmptyComponent>
    </section>
    <div class="family-list-footer" />
  </v-container>
</template>
<script>
import { EmptyComponent, SpinnerComponent } from "@/components/utils";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable.js";
import { RequestStatusFeedbackComponent } from "@/components/catalogoEnLinea/purchaseFlow";
import moment from "moment";

export default {
  name: "FamiliesView",
  components: {
    SpinnerComponent,
    EmptyComponent,
    RequestStatusFeedbackComponent,
  },
  data: () => ({
    families: createLoadable([]),
  }),
  methods: {
    async fetchFamilies() {
      toggleLoadable(this.families);
      const { data } = await this.services.Family.getFamilies();
      setLoadableResponse(this.families, data);
    },

    calculateButtonStyle(nombre) {
      let baseFontSize = 8; // Tamaño base de la fuente en píxeles
      let maxLength = 18; // Longitud máxima del texto para el tamaño base de la fuente
      let fontSize = Math.max(baseFontSize * (maxLength / nombre.length), 10); // Ajusta el tamaño de la fuente basado en la longitud del texto, no menor a 10px
      return {
        fontSize: `${fontSize}px`,
      };
    },
  },
  created() {
    this.fetchFamilies();
  },
};
</script>
<style scoped>
.blue-gradient-btn {
  background: linear-gradient(to right, #00bfff, #007bff);
  width: 130px;
}

.adjustable-button {
  white-space: normal;
  text-align: center;
  display: block;
  overflow: hidden;
  font-size: 8px;
}

.family-list {
  list-style: none;
  width: 100%;
}

/** Layout **/
.family-list-main {
  position: relative;
  z-index: 10;
}

.family-list-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #313945;
  width: 100%;
  z-index: 100;
}

.family-list-footer::before {
  content: "";
  background-color: #2db3e0;
  width: 70%;
  height: 10px;
  top: -10px;
  left: 0;
  position: absolute;
}

.family-list-footer::after {
  content: "";
  background-color: #292a4e;
  width: 30%;
  height: 10px;
  top: -10px;
  right: 0;
  position: absolute;
}

/** card **/
:deep(.card-title) {
  max-height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}
</style>
